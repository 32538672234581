import * as XLSX from 'xlsx';

const headerStyles = {
  headerDark: {
    fill: '87CEEB',
    font: {
      color: 'FF000000',
      sz: 14,
      bold: true,
      underline: true
    }
  }
};

const getHeading = () => {
  return [
    ['Report Type', 'RAG Score'],
    ['Start Date', 'Start Time', 'End Date', 'End Time'],
  ];
};

const ensureArrayOfArrays = (data) => {
  if (Array.isArray(data) && data.length > 0 && !Array.isArray(data[0])) {
    return data.map(item => Object.values(item));
  }
  return data;
};

const generateExcelReport = (data, name, dateFrom, dateTo, startTime, endTime) => {
  try {
    const heading = getHeading();
    
    const formattedData = ensureArrayOfArrays(data);
    
    const wb = XLSX.utils.book_new();
    
    const reportInfo = [

      [dateFrom, startTime, dateTo, endTime],
      [],
      ["Plate Number", "Harsh Acceleration per 100 KM", "Harsh Brake per 100 KM", "Over Speeding per 100 KM", "Total RAG Score"]
    ];
    
    const wsData = heading.concat(reportInfo, formattedData);

    const ws = XLSX.utils.aoa_to_sheet(wsData);

    XLSX.utils.book_append_sheet(wb, ws, 'RAG Score Report');

    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

    const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });

    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.href = url;
    link.setAttribute('download', `${name}.xlsx`); 
    document.body.appendChild(link);
    link.click(); 
    document.body.removeChild(link); 
    URL.revokeObjectURL(url);
  } catch (error) {
    console.error('Error generating report:', error);
  }
};

export default generateExcelReport;
