import React, { Component } from "react";
import { NavLink, withRouter } from "react-router-dom";
import "./NavMenu.css";
import headerIcon from "../../assets/img/AUXO-Vert-Light.png";
import overviewIcon from "../../assets/img/overview.png";
import vehicleIcon from "../../assets/img/vehicles.png";
import driversIcon from "../../assets/img/drivers.png";
import settingsIcon from "../../assets/img/settings.png";
import reportsIcon from "../../assets/img/reports.png";
import ic_schedule from "../../assets/img/ic_schedule.png";
import UserAuthentication from "../../ducks/UserAuthentication";
import Reports from "../../ducks/Reports";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { UncontrolledCollapse } from "reactstrap";
import geofenceIcon from "../../assets/img/geofence.png";
import ic_trip from "../../assets/img/ic_trip.png";
import ic_group from "../../assets/img/ic_group.png";
import fuel_average from "../../assets/img/fuel-average.png";
import observationIcon from "../../assets/img/Observation icon.png";
import playbackIcon from "../../assets/img/Playback icon.png";
import Modal from 'react-bootstrap4-modal';
import Icon from 'react-icons-kit'
import {dashboard} from 'react-icons-kit/fa/dashboard'
import {version} from '../../../package.json'
import {ban} from 'react-icons-kit/fa/ban'
import { faBroadcastTower } from "@fortawesome/free-solid-svg-icons/faBroadcastTower";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationArrow } from "@fortawesome/free-solid-svg-icons/faLocationArrow";



class NavMenu extends Component {
  constructor() {
    super();
    try {
      let groupData = JSON.parse(localStorage.getItem("groupInfo"));
      let companyInfo = JSON.parse(localStorage.getItem("companyInfo"));

      if (!groupData) {
        groupData = {
          depthLevel: 3,
        };
      }
      this.state = {
        groupDepthLevel: groupData.depthLevel,
        companyId: companyInfo.companyId || null,
        openSetting: false,
        userId:localStorage.getItem("user_id"),
        openFence: false,
        openHotspot: false,
        openPatrolling: false,
        openRoster: false,
        openVisualization: false,
        openReports: false,
      };
    } catch (err) {}
    this.toggle = this.toggle.bind(this);
    this.navigateToLogs = this.navigateToLogs.bind(this);
  }
  openSetting() {
    this.setState({
      ...this.state,
      openSetting: !this.state.openSetting,
    });
  }
  openFence() {
    this.setState({
      ...this.state,
      openFence: !this.state.openFence,
    });
  }
  openHotspot() {
    this.setState({
      ...this.state,
      openHotspot: !this.state.openHotspot,
    });
  }
  openReports() {
    this.setState({
      ...this.state,
      openReports: !this.state.openReports,
    });
  }
  openPatrolling() {
    this.setState({
      ...this.state,
      openPatrolling: !this.state.openPatrolling,
    });
  }
  openRoster() {
    this.setState({
      ...this.state,
      openRoster: !this.state.openRoster,
    });
  }
  openVisualization() {
    this.setState({
      ...this.state,
      openVisualization: !this.state.openVisualization,
    });
  }
  logoutUser = async () => {
    await this.props.logoutActions.logoutUser();
    this.props.history.push("/login");
  };

  toggle = () => {
    this.props.reportActions.toggleModal();
  };

  navigateToLogs = () => {
    this.props.reportActions.toggleModal();
    this.props.history.push("/download/reports");
  };

  render() {
    const isSuperAdminUser = localStorage.getItem("isSuperAdminUser");
    const permissions = this.props.permissions;
    return (
      <>
        <nav className="col-sm-3 col-md-2 d-none d-sm-block bg-faded sidebar">
       
          <NavLink className="sidebar-header" exact to="/">
            {
              <img
                src={headerIcon}
                alt="Auxo Fleet"
                className="p-2 header-icon-auxo"
              />
            }
            {/* {<img  src={headerIconBeta} alt="Auxo Fleet" className="p-2 header-icon-auxo"/>} */}
          </NavLink>
          <div className="nav-divider" />
          <ul className="nav nav-pills flex-column">
            {permissions.companyFeatures.indexOf("DASHBOARD:VIEWS") !== -1 &&
              (permissions.dashboard || isSuperAdminUser) && (
                <>
                 <li className="nav-item">
                 
                  <NavLink
                    className="nav-link "
                    activeclassname="active"
                    exact
                    to="/"
                  >
                    <span className="nav-item-box">
                      <img
                        className="nav-item-icon"
                        src={overviewIcon}
                        alt=""
                      />
                      <span className="nav-text">Dashboard</span>
                    </span>
                  </NavLink>
                </li>
               {this.state && this.state.companyId && this.state.companyId === "606b15ed8d1b9f7336b0bee5" && (
                <>
                 <li className="nav-item">
                  <NavLink
                    className="nav-link "
                    activeclassname="active"
                    exact
                    to="/RAGScore"
                  >
                    <span className="nav-item-box bat-special">
                      <Icon size={20} icon={dashboard} className="nav-item-icon"/>
                      {/* <img
                        className="nav-item-icon"
                        src={overviewIcon}
                        alt=""
                      /> */}
                      <span className="nav-text">RAG Score</span>
                    </span>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className="nav-link "
                    activeclassname="active"
                    exact
                    to="/CardinalViolations"
                  >
                    <span className="nav-item-box bat-special">
                      <Icon size={20} icon={ban} className="nav-item-icon"/>
                      {/* <img
                        className="nav-item-icon"
                        src={overviewIcon}
                        alt=""
                      /> */}
                      <span className="nav-text">Cardinal Violations</span>
                    </span>
                  </NavLink>
                </li>
                </>
               )}
                </>
              )}
            {permissions.companyFeatures.indexOf("PATROLLING") !== -1 &&
              (permissions.patrolling || isSuperAdminUser) && (
                <li className="nav-item">
                  <a
                    className="nav-link "
                    activeclassname="active"
                    id="patrollingToggle"
                    onClick={this.openPatrolling.bind(this)}
                  >
                    <span className="nav-item-box">
                      <img
                        className="nav-item-icon"
                        src={overviewIcon}
                        alt=""
                      />
                      <span className="nav-text">
                        Patrolling{" "}
                        <i
                          className={
                            this.state.openPatrolling
                              ? "fa-caret-down fa float-right mt-lg-3"
                              : "fa-caret-right fa float-right mt-lg-3"
                          }
                        />
                      </span>
                    </span>
                  </a>
                  <UncontrolledCollapse
                    toggler="#patrollingToggle"
                    className="collapse-nav"
                  >
                    <ul className="nav nav-pills flex-column">
                      <li className="nav-item">
                        <NavLink
                          className="nav-link "
                          activeclassname="active"
                          to="/patrolling/dashboard"
                        >
                          <span className="nav-item-box">
                            <span className="nav-text">Dashboard</span>
                          </span>
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          className="nav-link "
                          activeclassname="active"
                          to="/patrolling/cellsites"
                        >
                          <span className="nav-item-box">
                            <span className="nav-text">Cell Sites</span>
                          </span>
                        </NavLink>
                      </li>
                      {/* <li className="nav-item">
                    <NavLink className="nav-link " activeclassname="active" to="/patrolling/regions">
                      <span className="nav-item-box">
                        <span className="nav-text">Regions</span>
                      </span>
                    </NavLink>
                  </li> */}
                      <li className="nav-item">
                        <NavLink
                          className="nav-link "
                          activeclassname="active"
                          to="/patrolling/reports"
                        >
                          <span className="nav-item-box">
                            <span className="nav-text">Reports</span>
                          </span>
                        </NavLink>
                      </li>
                    </ul>
                  </UncontrolledCollapse>
                </li>
              )}
            {permissions.companyFeatures.indexOf("ROSTER") !== -1 &&
              (permissions.roster || isSuperAdminUser) && (
                <li className="nav-item">
                  <a
                    className="nav-link "
                    activeclassname="active"
                    id="rosterToggle"
                    onClick={this.openRoster.bind(this)}
                  >
                    <span className="nav-item-box">
                      <img
                        className="nav-item-icon"
                        src={overviewIcon}
                        alt=""
                      />
                      <span className="nav-text">
                        Roster{" "}
                        <i
                          className={
                            this.state.openRoster
                              ? "fa-caret-down fa float-right mt-lg-3"
                              : "fa-caret-right fa float-right mt-lg-3"
                          }
                        />
                      </span>
                    </span>
                  </a>
                  <UncontrolledCollapse
                    toggler="#rosterToggle"
                    className="collapse-nav"
                  >
                    <ul className="nav nav-pills flex-column">
                      <li className="nav-item">
                        <NavLink
                          className="nav-link "
                          activeclassname="active"
                          to="/roster/list"
                        >
                          <span className="nav-item-box">
                            <span className="nav-text">Manage</span>
                          </span>
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          className="nav-link "
                          activeclassname="active"
                          to="/rosters/reports"
                        >
                          <span className="nav-item-box">
                            <span className="nav-text">Reports</span>
                          </span>
                        </NavLink>
                      </li>
                    </ul>
                  </UncontrolledCollapse>
                </li>
              )}
              {permissions.companyFeatures.indexOf("MVDR_VIDEO") !== -1 &&
               (
                <li className="nav-item">
                  <a
                    className="nav-link "
                    activeclassname="active"
                    id="videoToggle"
                    onClick={this.openRoster.bind(this)}
                  >
                    <span className="nav-item-box">
                      <img
                        className="nav-item-icon"
                        src={overviewIcon}
                        alt=""
                      />
                      <span className="nav-text">
                        Video{" "}
                        <i
                          className={
                            this.state.openRoster
                              ? "fa-caret-down fa float-right mt-lg-3"
                              : "fa-caret-right fa float-right mt-lg-3"
                          }
                        />
                      </span>
                    </span>
                  </a>
                  <UncontrolledCollapse
                    toggler="#videoToggle"
                    className="collapse-nav"
                  >
                    <ul className="nav nav-pills flex-column">
                      <li className="nav-item">
                        <NavLink
                          className="nav-link "
                          activeclassname="active"
                          to="/video/live"
                        >
                          <span className="nav-item-box">
                            <span className="nav-text">Live Video</span>
                          </span>
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          className="nav-link "
                          activeclassname="active"
                          to="/video/playback"
                        >
                          <span className="nav-item-box">
                            <span className="nav-text">Play Back</span>
                          </span>
                        </NavLink>
                      </li>
                    </ul>
                  </UncontrolledCollapse>
                </li>
              )}
            {(permissions.viewVehicle || isSuperAdminUser) &&
              this.state.companyId !== "5fd0a1fb6dcde56e0a6c1f9e" && (
                <li className="nav-item">
                  <NavLink
                    className="nav-link "
                    activeclassname="active"
                    exact
                    to="/vehicles/list"
                  >
                    <span className="nav-item-box">
                      <img className="nav-item-icon" src={vehicleIcon} alt="" />
                      <span className="nav-text">{permissions.companyFeatures.indexOf("TEMPERATURE") === -1 ? 'Vehicles' : 'Devices'}</span>
                    </span>
                  </NavLink>
                </li>
              )}
            {permissions.companyFeatures.indexOf("TRIP:HISTORY") !== -1 &&
              (permissions.trips || isSuperAdminUser) && (
                <li className="nav-item">
                  <NavLink
                    className="nav-link "
                    activeclassname="active"
                    exact
                    to="/vehicles/trips"
                  >
                    <span className="nav-item-box">
                      <img
                        className="nav-item-icon-trip"
                        src={ic_trip}
                        alt=""
                      />
                      <span className="nav-text">Trips</span>
                    </span>
                  </NavLink>
                </li>
              )}
            {permissions.companyFeatures.indexOf("PLAYBACK") !== -1 &&
              (permissions.playBack || isSuperAdminUser) && (
                <li className="nav-item">
                  <NavLink
                    className="nav-link "
                    activeclassname="active"
                    exact
                    to="/vehicles/track-playback"
                  >
                    <span className="nav-item-box">
                      <img
                        className="nav-item-icon-trip"
                        src={playbackIcon}
                        style={{ width: "30px", marginRight: "7px" }}
                        alt=""
                      />
                      <span className="nav-text">Playback</span>
                    </span>
                  </NavLink>
                </li>
              )}
              {this.state?.companyId === "64e702f760f08366ac0d9e71" && (
                <li className="nav-item andr">
                  <NavLink
                    className="nav-link "
                    activeclassname="active"
                    exact
                    to="/TelcoSites"
                  >
                    <span className="nav-item-box align-andr">
                    <FontAwesomeIcon icon={faBroadcastTower} />
                      {/* <Icon size={20} icon={dashboard} className="nav-item-icon"/> */}
                      {/* <img
                        className="nav-item-icon"
                        src={overviewIcon}
                        alt=""
                      /> */}
                      <span className="nav-text margin-left-andr">Telco Site Activity</span>
                    </span>
                  </NavLink>
                </li>
              )}
               
            {permissions.companyFeatures.indexOf("OBSERVATIONS") !== -1 &&
              permissions.companyFeatures.indexOf("TEMPERATURE") === -1  && (

              (permissions.observations || isSuperAdminUser) &&
              this.state.companyId !== "5fd0a1fb6dcde56e0a6c1f9e" && 
              this.state.companyId !== "60e834a9a035ca2af17a4521" && (
                <li className="nav-item">
                  <NavLink
                    className="nav-link "
                    activeClassName="active"
                    to="/vehicles/observation"
                  >
                    <span className="nav-item-box">
                      <img
                        className="nav-item-icon"
                        src={observationIcon}
                        alt=""
                      />
                      <span className="nav-text">Observations</span>
                    </span>
                  </NavLink>
                </li>
              )
              )
            }
            {
              
              this.props.permissions.companyFeatures.indexOf("ROUTER") !== -1  && (
                <li className="nav-item">
                  <NavLink
                    className="nav-link "
                    activeClassName="active"
                    to="/router/observation"
                  >
                    <span className="nav-item-box">
                      <img
                        className="nav-item-icon"
                        src={observationIcon}
                        alt=""
                      />
                      <span className="nav-text">Observations</span>
                    </span>
                  </NavLink>
                </li>
              )}
              {
              this.props.permissions.companyFeatures.indexOf("TEMPERATURE") !== -1  && (
                <li className="nav-item">
                  <NavLink
                    className="nav-link "
                    activeClassName="active"
                    to="/temperature"
                  >
                    <span className="nav-item-box">
                      <img
                        className="nav-item-icon"
                        src={observationIcon}
                        alt=""
                      />
                      <span className="nav-text">Temperature</span>
                    </span>
                  </NavLink>
                </li>
              )
              }
            {
              this.props.permissions.companyFeatures.indexOf("OBSERVATIONS") !== -1 &&
              this.props.permissions.companyFeatures.indexOf("TEMPERATURE") !== -1 && 
              // this.state &&
              // (this.state.companyId === "5fd0a1fb6dcde56e0a6c1f9e" ||
              //   this.state.companyId === "603f24e4fe792949f28592c4" ||
              //   this.state.companyId === "60e834a9a035ca2af17a4521" ||
              //   this.state.companyId === "603f7be06a23817d9e06a2f4") && 
                (
                <li className="nav-item">
                  <NavLink
                    className="nav-link "
                    activeClassName="active"
                    to="/vehicles/chiller-observation"
                  >
                    <span className="nav-item-box">
                      <img
                        className="nav-item-icon"
                        src={observationIcon}
                        alt=""
                      />
                      <span className="nav-text">
                        {this.state.companyId === "603f7be06a23817d9e06a2f4"
                          ? "Fuel Observations"
                          : this.state.companyId === "60e834a9a035ca2af17a4521" ? "Temperature Logs": "Chiller Logs"}
                      </span>
                    </span>
                  </NavLink>
                </li>
              )}
            {/* {this.state && this.state.companyId === '5fd0a1fb6dcde56e0a6c1f9e' &&

            <li className="nav-item">
              <NavLink className="nav-link " activeClassName="active" to="/video">
                <span className="nav-item-box">
                  <img className="nav-item-icon" src={observationIcon} alt="" />
                  <span className="nav-text">MVR</span>
                </span>
              </NavLink>
            </li>
          } */}
            {permissions.companyFeatures.indexOf("ECU_OBSERVATIONS") !== -1 &&
              (permissions.observations || isSuperAdminUser) && (
                <li className="nav-item">
                  <NavLink
                    className="nav-link "
                    activeClassName="active"
                    to="/vehicles/ecu-observation"
                  >
                    <span className="nav-item-box">
                      <img
                        className="nav-item-icon"
                        src={observationIcon}
                        alt=""
                      />
                      <span className="nav-text">ECU Data</span>
                    </span>
                  </NavLink>
                </li>
              )}

            {permissions.companyFeatures.indexOf("DRIVER:MANAGEMENT") !== -1 &&
              (permissions.viewDriver || isSuperAdminUser) && (
                <li className="nav-item">
                  <NavLink
                    className="nav-link "
                    activeclassname="active"
                    exact
                    to="/drivers"
                  >
                    <span className="nav-item-box">
                      <img className="nav-item-icon" src={driversIcon} alt="" />
                      <span className="nav-text">Drivers</span>
                    </span>
                  </NavLink>
                </li>
              )}   
            {( permissions.companyFeatures.indexOf("GDTHINGS_VISUALIZATION") !== -1 || 
               permissions.companyFeatures.indexOf("TRIPS_VISUALIZATION") !== -1 || 
               permissions.companyFeatures.indexOf("FUEL_VISUALIZATION") !== -1  || 
               permissions.companyFeatures.indexOf("VIOLATION_VISUALIZATION") !== -1 ) && (
              <li className="nav-item">
                <a
                  className="nav-link "
                  activeclassname="active"
                  id="visualizationToggle"
                  onClick={this.openVisualization.bind(this)}
                >
                  <span className="nav-item-box">
                    <img className="nav-item-icon" src={driversIcon} alt="" />
                    <span className="nav-text">
                      Visualization{" "}
                      <i
                        className={
                          this.state.openVisualization
                            ? "fa-caret-down fa float-right mt-lg-3"
                            : "fa-caret-right fa float-right mt-lg-3"
                        }
                      />
                    </span>
                  </span>
                </a>
                <UncontrolledCollapse
                  toggler="#visualizationToggle"
                  className="collapse-nav"
                >
                  <ul className="nav nav-pills flex-column">
               
              {permissions.companyFeatures.indexOf("VIOLATION_VISUALIZATION") !== -1 && (
                    <li className="nav-item">
                      <NavLink
                        className="nav-link "
                        activeclassname="active"
                        exact
                        to="/visualization/violations"
                      >
                        <span className="nav-item-box">
                          <span className="nav-text">Violation</span>
                        </span>
                      </NavLink>
                    </li>
               )}
              {permissions.companyFeatures.indexOf("TRIPS_VISUALIZATION") !== -1 && (
                      <li className="nav-item">
                        <NavLink
                          className="nav-link "
                          activeclassname="active"
                          exact
                          to="/visualization/trips"
                        >
                          <span className="nav-item-box">
                            <span className="nav-text">Trips</span>
                          </span>
                        </NavLink>
                      </li>
               )}
              {permissions.companyFeatures.indexOf("GDTHINGS_VISUALIZATION") !== -1 && (
                      <li className="nav-item">
                        <NavLink
                          className="nav-link "
                          activeclassname="active"
                          exact
                          to="/visualization/gdthings"
                        >
                          <span className="nav-item-box">
                            <span className="nav-text">Gd Things</span>
                          </span>
                        </NavLink>
                      </li>
               )}
               {this.state.companyId.toString() === "62f3be2fad2b772bb8cff1a1" && (
                      <li className="nav-item">
                        <NavLink
                          className="nav-link "
                          activeclassname="active"
                          exact
                          to="/visualization/fuelnew"
                        >
                          <span className="nav-item-box">
                            <span className="nav-text">Fuel New</span>
                          </span>
                        </NavLink>
                      </li>
               )}
              {permissions.companyFeatures.indexOf("FUEL_VISUALIZATION") !== -1 && (
                      <li className="nav-item">
                        <NavLink
                          className="nav-link "
                          activeclassname="active"
                          exact
                          to="/visualization/fuel"
                        >
                          <span className="nav-item-box">
                            <span className="nav-text">Fuel</span>
                          </span>
                        </NavLink>
                      </li>
              )}
                  </ul>
                </UncontrolledCollapse>
              </li>
            )}
            {permissions.companyFeatures.indexOf("DRIVER:MANAGEMENT") !== -1 &&
              ((permissions.viewDriver && permissions.listSchedule) ||
                isSuperAdminUser) && (
                <li className="nav-item">
                  <NavLink
                    className="nav-link "
                    activeclassname="active"
                    exact
                    to="/schedule"
                  >
                    <span className="nav-item-box">
                      <img className="nav-item-icon" src={ic_schedule} alt="" />
                      <span className="nav-text">Schedule</span>
                    </span>
                  </NavLink>
                </li>
              )}
                <li className="nav-item">
                  <NavLink
                    className="nav-link "
                    activeclassname="active"
                    exact
                    to="/complaints"
                  >
                    <span className="nav-item-box">
                      <img className="nav-item-icon" src={ic_schedule} alt="" />
                      <span className="nav-text">Complaint</span>
                    </span>
                  </NavLink>
                </li>
            {permissions.companyFeatures.indexOf("MAINTENANCE") !== -1 &&
              (permissions.maintenance || isSuperAdminUser) && (
                <li className="nav-item">
                  <NavLink
                    className="nav-link "
                    activeclassname="active"
                    exact
                    to="/vehicles/details/nil"
                  >
                    <span className="nav-item-box">
                      <img className="nav-item-icon" src={vehicleIcon} alt="" />
                      <span className="nav-text">Maintenance</span>
                    </span>
                  </NavLink>
                </li>
              )}
            {permissions.companyFeatures.indexOf("FUEL:GROUP") !== -1 &&
              (permissions.fuelGroup || isSuperAdminUser) && (
                <li className="nav-item">
                  <NavLink
                    className="nav-link "
                    activeclassname="active"
                    exact
                    to="/fuel-group"
                  >
                    <span className="nav-item-box">
                      <img
                        className="nav-item-icon"
                        src={fuel_average}
                        alt=""
                      />
                      <span className="nav-text">Fuel Group</span>
                    </span>
                  </NavLink>
                </li>
              )}
            {permissions.companyFeatures.indexOf("GROUPS") !== -1 &&
            this.state.groupDepthLevel !== 7 &&
            (permissions.viewGroup || isSuperAdminUser) ? (
              <li className="nav-item">
                <NavLink
                  className="nav-link "
                  activeclassname="active"
                  exact
                  to="/groups"
                >
                  <span className="nav-item-box">
                    <img className="nav-item-icon" src={ic_group} alt="" />
                    <span className="nav-text">Groups</span>
                  </span>
                </NavLink>
              </li>
            ) : (
              ""
            )}

            {(permissions.companyFeatures.indexOf("GEO-FENCE") !== -1 ||
              permissions.companyFeatures.indexOf("ROUTE-FENCE") !== -1) &&
            (permissions.viewGeoFence ||
              permissions.viewRouteFence ||
              isSuperAdminUser) ? (
              <li className="nav-item">
                <a
                  className="nav-link "
                  activeclassname="active"
                  id="fenceToggle"
                  onClick={this.openFence.bind(this)}
                >
                  <span className="nav-item-box">
                    <img className="nav-item-icon" src={geofenceIcon} alt="" />
                    <span className="nav-text">
                      Geofence{" "}
                      <i
                        className={
                          this.state.openFence
                            ? "fa-caret-down fa float-right mt-lg-3"
                            : "fa-caret-right fa float-right mt-lg-3"
                        }
                      />
                    </span>
                  </span>
                </a>
                <UncontrolledCollapse
                  toggler="#fenceToggle"
                  className="collapse-nav"
                >
                  <ul className="nav nav-pills flex-column">
                    {permissions.companyFeatures.indexOf("GEO-FENCE") !== -1 &&
                      (permissions.viewGeoFence || isSuperAdminUser) && (
                        <li className="nav-item">
                          <NavLink
                            className="nav-link "
                            activeclassname="active"
                            to="/geofence/list"
                          >
                            <span className="nav-item-box">
                              <span className="nav-text">Area</span>
                            </span>
                          </NavLink>
                        </li>
                      )}
                    {permissions.companyFeatures.indexOf("ROUTE-FENCE") !==
                      -1 &&
                      (permissions.viewRouteFence || isSuperAdminUser) && (
                        <li className="nav-item">
                          <NavLink
                            className="nav-link "
                            activeclassname="active"
                            to="/routefence/list"
                          >
                            <span className="nav-item-box">
                              <span className="nav-text">Route</span>
                            </span>
                          </NavLink>
                        </li>
                      )}
                  </ul>
                </UncontrolledCollapse>
              </li>
            ) : (
              ""
            )}

            {permissions.companyFeatures.indexOf("HOTSPOT") !== -1 && (
              <li className="nav-item">
                <a
                  className="nav-link "
                  activeclassname="active"
                  id="hotspotToggle"
                  onClick={this.openHotspot.bind(this)}
                >
                  <span className="nav-item-box">
                    <img className="nav-item-icon" src={geofenceIcon} alt="" />
                    <span className="nav-text">
                      Hotspot{" "}
                      <i
                        className={
                          this.state.openHotspot
                            ? "fa-caret-down fa float-right mt-lg-3"
                            : "fa-caret-right fa float-right mt-lg-3"
                        }
                      />
                    </span>
                  </span>
                </a>
                <UncontrolledCollapse
                  toggler="#hotspotToggle"
                  className="collapse-nav"
                >
                  <ul className="nav nav-pills flex-column">
                    <li className="nav-item">
                      <NavLink
                        className="nav-link "
                        activeclassname="active"
                        to="/hotspot/list"
                      >
                        <span className="nav-item-box">
                          <span className="nav-text">Hotspot</span>
                        </span>
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink
                        className="nav-link "
                        activeclassname="active"
                        to="/pjp"
                      >
                        <span className="nav-item-box">
                          <span className="nav-text">PJP</span>
                        </span>
                      </NavLink>
                    </li>
                  </ul>
                </UncontrolledCollapse>
              </li>
            )}

            {permissions.companyFeatures.indexOf("REPORTS") !== -1 &&
              (permissions.reports || isSuperAdminUser) && (
                <li className="nav-item">
                  <a
                    className="nav-link "
                    activeclassname="active"
                    id="reportsToggle"
                    onClick={this.openReports.bind(this)}
                  >
                    <span className="nav-item-box">
                      <img className="nav-item-icon" src={reportsIcon} alt="" />
                      <span className="nav-text">
                        Reports{" "}
                        <i
                          className={
                            this.state.openReports
                              ? "fa-caret-down fa float-right mt-lg-3"
                              : "fa-caret-right fa float-right mt-lg-3"
                          }
                        />
                      </span>
                    </span>
                  </a>
                  <UncontrolledCollapse
                    toggler="#reportsToggle"
                    className="collapse-nav"
                  >
                    <ul className="nav nav-pills flex-column">
                      <li className="nav-item">
                        <NavLink
                          className="nav-link "
                          activeClassName="active"
                          to="/reports"
                        >
                          <span className="nav-item-box">
                            <span className="nav-text">Request Reports</span>
                          </span>
                        </NavLink>
                        <NavLink
                          className="nav-link "
                          activeClassName="active"
                          to="/download/reports"
                        >
                          <span className="nav-item-box">
                            <span className="nav-text">View Reports</span>
                          </span>
                        </NavLink>
                      </li>
                    </ul>
                  </UncontrolledCollapse>
                </li>
              )}

            {(permissions.AccountSetting ||
              permissions.listUser ||
              permissions.listRole ||
              isSuperAdminUser) && (
              <li className="nav-item">
                <a
                  className="nav-link "
                  activeclassname="active"
                  id="settingsToggle"
                  onClick={this.openSetting.bind(this)}
                >
                  <span className="nav-item-box">
                    <img className="nav-item-icon" src={settingsIcon} alt="" />
                    <span className="nav-text">
                      Settings{" "}
                      <i
                        className={
                          this.state && this.state.openSetting
                            ? "fa-caret-down fa float-right mt-lg-3"
                            : "fa-caret-right fa float-right mt-lg-3"
                        }
                      />
                    </span>
                  </span>
                </a>
                <UncontrolledCollapse
                  toggler="#settingsToggle"
                  className="collapse-nav"
                >
                  <ul className="nav nav-pills flex-column">
                    {(permissions.AccountSetting || isSuperAdminUser) && 
                     permissions.companyFeatures.indexOf("TEMPERATURE") == -1 && 

                    (
                      <li className="nav-item">
                        <NavLink
                          className="nav-link "
                          activeclassname="active"
                          to="/account"
                        >
                          <span className="nav-item-box">
                            <span className="nav-text">Account</span>
                          </span>
                        </NavLink>
                      </li>
                    )}
                    {permissions.listUser || isSuperAdminUser ? (
                      <li className="nav-item">
                        <NavLink
                          className="nav-link "
                          activeclassname="active"
                          to="/users"
                        >
                          <span className="nav-item-box">
                            <span className="nav-text">Users</span>
                          </span>
                        </NavLink>
                      </li>
                    ) : (
                      ""
                    )}
                    {(permissions.listRole || isSuperAdminUser) 
                    && permissions.companyFeatures.indexOf("TEMPERATURE") == -1 && 
                    (
                      <li className="nav-item">
                        <NavLink
                          className="nav-link "
                          activeclassname="active"
                          to="/roles"
                        >
                          <span className="nav-item-box">
                            <span className="nav-text">Roles</span>
                          </span>
                        </NavLink>
                      </li>
                    ) }
                  </ul>
                </UncontrolledCollapse>
              </li>
            )}
            {/*
                <li className="nav-item logout-link">
                    <a className="nav-link" onClick={this.logoutUser}>
                     <span className="nav-item-box">
                         <img className="nav-item-icon" src={logoutIcon} alt=""/>
                         <span className="nav-text">Logout</span>
                     </span>
                    </a>
                </li> */}
          <li className="nav-item">
          <span className="nav-item-box">
                    <span style={{margin:"1.2rem"}} className="nav-text">
                      Version: {version}
                    </span>
                  </span>
          </li>
          </ul>
        </nav>
        <Modal
          visible={this.props.modal}
          dialogClassName="delete-modal-dialog"
        >
          <div>
            <button
              type="button"
              className="close close-x"
              aria-label="Close"
              onClick={() => this.toggle()}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-heading">
            <h5 className="car-and-device-assig">Report Request</h5>
          </div>
          <div className="modal-body">
            <h6>Your report is being generated. Please check log section.</h6>
          </div>
          <div className="modal-footer model-btn">
            <button
              className="btn auxo-primary-btn"
              onClick={() => this.navigateToLogs()}
            >
              View Reports
            </button>{" "}
            <button
              className="btn auxo-primary-btn"
              onClick={() => this.toggle()}
            >
              Cancel
            </button>
          </div>
        </Modal>
      </>
    );
  }
}

NavMenu.propTypes = {};
NavMenu.defaultProps = {};
const mapStateToProps = (state) => ({
  permissions: state.permissions,
  modal: state.Reports.modal,
});

const mapDispatchToProps = (dispatch) => ({
  logoutActions: bindActionCreators(UserAuthentication.creators, dispatch),
  reportActions: bindActionCreators(Reports.creators, dispatch),
});
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NavMenu)
);
